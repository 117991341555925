import { Injectable, EventEmitter, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from './ui.service';
import {
  VisualItem,
  VuState,
  ShopState,
  Workflow,
  WorkflowStep,
  WorkflowStepState,
  MachineInactivity,
  Teaser,
  TeaserType,
  Ticket,
  TicketUse,
  TicketUseInfo,
  Configuration,
  VuRole,
  CashDevicesState,
  PrintTaskType,
  MessageView,
  Money,
  WorkflowStepType,
  AcceptedCash,
  PaymentMethod,
} from '../lib/lib';

import {
  VuActionType,
  ShopActionType,
  PAGE_HEADER_SET,
  VISUAL_ITEM_SELECTED,
  SIMULATOR_IS_TEST_RUNNING,
  SimulatorState,
  WorkflowActionType,
  BackButtonActionType,
  TeaserActionType,
  TicketActionType,
  TicketUseInfoActionType,
  ConfigurationActionType,
  CashDevicesStateActionType,
  MessageViewActionType,
  PAGE_HEADER_VISIBLE,
  PAGE_FOOTER_VISIBLE,
  AbortButtonActionType,
} from '../reducers/reducers';
import { IVuConnection } from './vu/connection/vu-connection.interfaces';
import { IVuHttp } from './vu/http/vu-http.interface';
import { MessageService, MessageType, Message } from './message.service';
import { VuCommunicationService } from './vu/vu-communication.service';
import { BarcodeInputMethod } from '../lib/barcode-reader/barcode-input-method';
import { CreditCardTerminalSimulatorService } from './credit-card-terminal-simulator.service';
import { CreditCardTerminalState } from '../lib/credit-card/credit-card-terminal-state';
import { OneLineArticleSaleMode } from '../lib/one-line-article-sale-mode';
import { OpenGateTypeEnum } from '../lib/gate/open-gate-type.enum';
import { ExternalCardRechargeModel } from '../modules/external-payment/models/external-card-recharge-model';
import { filter } from 'rxjs/operators';
import { MachineSaleCashlessService } from './machines/machine-sale-cashless.service';
import { CustomShopInformation } from '../lib/custom-shop/custom-shop.information';

@Injectable()
export class DispatcherService {
  private internalVuConnection: IVuConnection;
  private internalVuHttp: IVuHttp;
  subscription: Subscription;
  eventServiceInitialized: EventEmitter<any> = new EventEmitter(true);
  eventMachineInactivityChangeTracking: EventEmitter<MachineInactivity> = new EventEmitter(false);
  eventUserActivity: EventEmitter<any> = new EventEmitter(true);
  private internalCreditCardTerminalSimulatorService: CreditCardTerminalSimulatorService;

  barcode: string;
  constructor(
    private store: Store<string>,
    private messageService: MessageService,
    private router: Router,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private uiService: UiService,
    private injector: Injector,
  ) {
    const scope = this;
    setTimeout(() => {
      const vuCommunicationService = scope.injector.get(VuCommunicationService);
      scope.internalVuConnection = vuCommunicationService.vuConnection;
      scope.internalVuConnection.eventVuStateChanged.subscribe((x: VuState) => scope.vuStateSet(x));
      scope.internalVuHttp = vuCommunicationService.vuHttp;
      scope.internalCreditCardTerminalSimulatorService = scope.injector.get(CreditCardTerminalSimulatorService);

      this.eventServiceInitialized.emit();
    }, 1);
  }

  get vuHttp(): IVuHttp {
    return this.internalVuHttp;
  }

  get vuConnection(): IVuConnection {
    return this.internalVuConnection;
  }

  pageHeaderSet(header = ''): void {
    this.pageHeaderLinesSet([header]);
  }

  pageHeaderLinesSet(headerLines = ['']): void {
    this.dispatch(PAGE_HEADER_SET, headerLines);
  }

  set isHeaderVisible(x: boolean) {
    this.dispatch(PAGE_HEADER_VISIBLE, x);
  }

  get isHeaderVisible(): boolean {
    return this.uiService.headerState.visible;
  }

  onBackButtonClick(): void {
    this.messageService.sendMessage(new Message(MessageType.ButtonBackClicked));
  }

  onAbortButtonClick(): void {
    this.sendMessage(MessageType.ButtonAbortClicked);
  }

  set isAbortButtonVisible(x: boolean) {
    this.dispatch(AbortButtonActionType.ABORT_BUTTON_VISIBLE, x);
  }

  get isAbortButtonVisible(): boolean {
    return this.uiService.abortButtonState.visible;
  }

  set isAbortButtonEnabled(x: boolean) {
    this.dispatch(AbortButtonActionType.ABORT_BUTTON_ENABLED, x);
  }

  get isAbortButtonEnabled(): boolean {
    return this.uiService.abortButtonState.enabled;
  }

  set isBackButtonVisible(x: boolean) {
    this.dispatch(BackButtonActionType.BACK_BUTTON_VISIBLE, x);
  }

  get isBackButtonVisible(): boolean {
    return this.uiService.backButtonState.visible;
  }

  set isBackButtonEnabled(x: boolean) {
    this.dispatch(BackButtonActionType.BACK_BUTTON_ENABLED, x);
  }

  get isBackButtonEnabled(): boolean {
    return this.uiService.backButtonState.enabled;
  }

  set isFooterVisible(x: boolean) {
    this.dispatch(PAGE_FOOTER_VISIBLE, x);
  }

  get isFooterVisible(): boolean {
    return this.uiService.footerState.visible;
  }

  backButtonTextSet(x: string): void {
    this.dispatch(BackButtonActionType.BACK_BUTTON_TEXT_SET, x);
  }

  backButtonTextReset(): void {
    this.dispatch(BackButtonActionType.BACK_BUTTON_TEXT_RESET);
  }

  back(): void {
    this.sendMessage(MessageType.Back);
  }

  onBackButtonVisualItemInfoClicked(): void {
    this.sendMessage(MessageType.BackButtonVisualItemInfoClicked);
  }

  onButtonSaleShopClick(): void {
    this.sendMessage(MessageType.ButtonSaleShopClicked);
  }

  onButtonInfoClick(): void {
    this.sendMessage(MessageType.ButtonInfoClicked);
  }

  onButtonTicketClick(): void {
    this.sendMessage(MessageType.ButtonTicketClicked);
  }

  onButtonTicketActivationClick(): void {
    this.sendMessage(MessageType.TicketActivation);
  }

  onButtonMoneyExchangeClick(): void {
    this.sendMessage(MessageType.ButtonMoneyExchangeClicked);
  }

  onButtonCustomSaleShopClick(customShopInformation: CustomShopInformation): void {
    this.sendMessage(MessageType.ButtonCustomSaleShopClicked, customShopInformation);
  }

  vuStateMaintenanceMode(x: boolean): void {
    this.dispatch(VuActionType.VU_STATE_MAINTENANCE_MODE, x);
  }

  vuStateOfflineMode(x: boolean): void {
    this.dispatch(VuActionType.VU_STATE_OFFLINE_MODE, x);
  }

  vuStateServiceMode(x: boolean): void {
    this.dispatch(VuActionType.VU_STATE_SERVICE_MODE, x);
  }

  vuMultipleAccessError(x: boolean): void {
    this.dispatch(VuActionType.VU_STATE_MULTIPLE_ACCESS_ERROR, x);
  }

  vuStateBurglaryMode(x: boolean): void {
    this.dispatch(VuActionType.VU_STATE_BURGLARY_MODE, x);
  }

  vuStateSet(x: VuState): void {
    this.dispatch(VuActionType.VU_STATE_ALL, x);
  }

  onVuStateChangedSubscribe(f: (x: VuState) => void): Subscription {
    return this.store.select<VuState>('vuState').subscribe(f);
  }

  cashDevicesStatePayIn(x: boolean): void {
    this.dispatch(CashDevicesStateActionType.CASH_DEVICES_STATE_PAY_IN, x);
  }

  cashDevicesStatePayOut(x: boolean): void {
    this.dispatch(CashDevicesStateActionType.CASH_DEVICES_STATE_PAY_OUT, x);
  }

  onCashDevicesStateSubscribe(f: (x: CashDevicesState) => void): Subscription {
    return this.store.select<CashDevicesState>('cashDevicesState').subscribe(f);
  }

  messageViewUpdate(x: MessageView): void {
    this.dispatch(MessageViewActionType.MESSAGE_VIEW_UPDATE, x);
  }

  messageViewText(x: string): void {
    this.dispatch(MessageViewActionType.MESSAGE_VIEW_TEXT, x);
  }

  onMessageViewSubscribe(f: (x: MessageView) => void): Subscription {
    return this.store.select<MessageView>('messageView').subscribe(f);
  }

  onTicketChangedSubscribe(f: (x: Ticket) => void): Subscription {
    return this.store.select<Ticket>('ticket').subscribe(f);
  }

  ticketReset(): void {
    this.dispatch(TicketActionType.TICKET_RESET);
  }

  ticketUpdate(x: Ticket): void {
    this.dispatch(TicketActionType.TICKET_UPDATE, x);
  }

  ticketOvertimePayment(): void {
    this.sendMessage(MessageType.TicketOvertimePayment);
  }

  ticketUse(x: Ticket): void {
    this.sendMessage(MessageType.TicketUse, x);
  }

  ticketUseInfoTicket(x: Ticket): void {
    this.dispatch(TicketUseInfoActionType.TICKET_USE_INFO_TICKET, x);
  }

  ticketUseInfoUse(x: TicketUse[]): void {
    this.dispatch(TicketUseInfoActionType.TICKET_USE_INFO_USE, x);
  }

  ticketReturnAmount(isDisplayMode: boolean): void {
    this.sendMessage(MessageType.TicketReturnAmount, isDisplayMode);
  }

  externalPayout(): void {
    this.sendMessage(MessageType.ExternalPayout);
  }

  externalCardRecharge(model: ExternalCardRechargeModel): void {
    this.sendMessage(MessageType.ExternalCardRecharge, model);
  }

  confirmPayment(): void {
    this.sendMessage(MessageType.ConfirmPayment);
  }

  onTicketUseInfoSubscribe(f: (x: TicketUseInfo) => void): Subscription {
    return this.store.select<TicketUseInfo>('ticketUseInfo').subscribe(f);
  }

  configurationUpdate(x: Configuration): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_UPDATE, x);
  }

  configurationVuRole(x: VuRole): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_VU_ROLE, x);
  }

  configurationLocale(x: string): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_LOCALE, x);
  }

  configurationShowArticlesOnMainPage(x: boolean): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_SHOW_ARTICLES_ON_MAIN_PAGE, x);
  }

  configurationPrintCardTerminalReceipt(x: string): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_PRINT_CARD_TERMINAL_RECEIPT, x);
  }

  configurationPrintOrderReceipt(x: string): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_PRINT_ORDER_RECEIPT, x);
  }

  configurationOneLineArticleSaleMode(x: OneLineArticleSaleMode): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_ONE_LINE_ARTICLE_SALE_MODE, x);
  }

  configurationBarcodeInputMethod(x: BarcodeInputMethod): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_BARCODE_INPUT_METHOD, x);
  }

  configurationTicketPrinterType(x: string): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_TICKET_PRINTER_TYPE, x);
  }

  configurationReceiptPrinterType(x: string): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_RECEIPT_PRINTER_TYPE, x);
  }

  configurationAdditionalProperties(x: any): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_ADDITIONAL_PROPERTIES, x);
  }

  configurationCustomCss(x: string): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_CUSTOM_CSS, x);
  }

  configurationCustomLogoId(x: number): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_CUSTOM_LOGO_ID, x);
  }

  configurationBackgorundId(x: number): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_BACKGROUND_ID, x);
  }

  configurationChangeDate(x: Date): void {
    this.dispatch(ConfigurationActionType.CONFIGURATION_CHANGE_DATE, x);
  }

  onConfigurationChangedSubscribe(f: (x: Configuration) => void): Subscription {
    return this.store.select<Configuration>('configuration').subscribe(f);
  }

  shopStateCanPayCash(x: boolean): void {
    this.dispatch(ShopActionType.SHOP_CAN_PAY_CASH, x);
  }

  shopStateCanPayCard(x: boolean): void {
    this.dispatch(ShopActionType.SHOP_CAN_PAY_CARD, x);
  }

  shopStateOrderUid(x: string): void {
    this.dispatch(ShopActionType.SHOP_ORDER_UID, x);
  }

  shopStateIsSavingOrder(x: boolean): void {
    this.dispatch(ShopActionType.SHOP_IS_ORDER_SAVING, x);
  }

  shopStatePrinting(type: PrintTaskType, x: boolean): void {
    const shopActionType = type === PrintTaskType.Ticket ?
      ShopActionType.SHOP_IS_TICKET_PRINTING : ShopActionType.SHOP_IS_RECEIPT_PRINTING;
    this.dispatch(shopActionType, x);
  }

  onShopStateChangedSubscribe(f: (x: ShopState) => void): Subscription {
    return this.store.select<ShopState>('shopState').subscribe(f);
  }

  onSimulatorStateChangedSubscribe(f: (x: SimulatorState) => void): Subscription {
    return this.store.select<SimulatorState>('simulatorState').subscribe(f);
  }

  onWorkflowStateChangedSubscribe(f: (x: Workflow) => void): Subscription {
    return this.store.select<Workflow>('workflow').subscribe(f);
  }

  workflowReset(name: string, stepType: WorkflowStepType, ...args: string[]): void {
    this.dispatch(WorkflowActionType.WORKFLOW_RESET, new Workflow(name, stepType, ...args));
  }

  workflowAddStep(stepType: WorkflowStepType, ...args: string[]): void {
    this.dispatch(WorkflowActionType.WORKFLOW_ADD_STEP, new WorkflowStep(stepType, ...args));
  }

  workflowDeleteLastStep(): void {
    this.dispatch(WorkflowActionType.WORKFLOW_DELETE_LAST_STEP);
  }

  workflowLastStepStateSet(state: WorkflowStepState = WorkflowStepState.CompleteSuccess): void {
    this.dispatch(WorkflowActionType.WORKFLOW_LAST_STEP_STATE_SET, state);
  }

  workflowLastStepUpdate(...args: string[]): void {
    this.dispatch(WorkflowActionType.WORKFLOW_LAST_STEP_UPDATE, args);
  }

  workflowAddAdditionalMessages(messages: string[]): void {
    this.dispatch(WorkflowActionType.WORKFLOW_ADD_ADDITIONAL_MESSAGES, messages);
  }

  workflowDeleteAdditionalMessages(): void {
    this.dispatch(WorkflowActionType.WORKFLOW_DELETE_ADDITIONAL_MESSAGES);
  }

  onTeaserChangedSubscribe(f: (x: Teaser) => void): Subscription {
    return this.store.select<Teaser>('teaser').subscribe(f);
  }

  teaserReset(): void {
    this.dispatch(TeaserActionType.TEASER_RESET);
  }

  teaserAddItem(x: TeaserType): void {
    this.dispatch(TeaserActionType.TEASER_ADD_ITEM, x);
  }

  teaserDeleteItem(x: TeaserType): void {
    this.dispatch(TeaserActionType.TEASER_DELETE_ITEM, x);
  }

  onUserActivity(): void {
    this.eventUserActivity.emit();
  }

  machineHardResetRoot(): void {
    this.sendMessage(MessageType.MachineHardResetRoot);
  }

  machineTimeoutModalCancel(machineName: string): void {
    this.sendMessage(MessageType.MachineTimeoutModalCancel, machineName);
  }

  simulatorIsTestRunningSet(x: boolean): void {
    this.dispatch(SIMULATOR_IS_TEST_RUNNING, x);
  }

  languagesButtonModalActivate(x: boolean): void {
    this.sendMessage(MessageType.LanguageButtonActivate, x);
  }

  onLanguagesButtonModalActivate(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.LanguageButtonActivate);
  }

  visualItemsInfoSet(x: VisualItem): void {
    this.dispatch(VISUAL_ITEM_SELECTED, x);
  }

  toPayment(paymentMethod: PaymentMethod, force: boolean = false, info: any = null): void {
    switch (paymentMethod) {
      case PaymentMethod.Cash:
        this.toPaymentCash(force);
        break;
      case PaymentMethod.PaymentCard:
        this.toPaymentCard(force);
        break;
      case PaymentMethod.Bluecode:
        this.toPaymentBluecode(force);
        break;
      case PaymentMethod.GiftCard:
        this.toPaymentGiftCard(info ? { force, ...info } : { force });
        break;
    }
  }

  toPaymentCash(force: boolean = false): void {
    this.sendMessage(MessageType.ToPaymentCash, { force });
  }

  toPaymentCard(force: boolean = false): void {
    this.sendMessage(MessageType.ToPaymentCard, { force });
  }

  toPaymentBluecode(force: boolean = false): void {
    this.sendMessage(MessageType.ToPaymentBluecode, { force });
  }

  toPaymentGiftCard(info: any = null): void {
    this.sendMessage(MessageType.ToPaymentGiftCard, info);
  }

  gateOpen(barcode: string, baseUrl: string = '', openGateType: string = OpenGateTypeEnum.Immediately, timeoutMs: number = 0): void {
    this.sendMessage(MessageType.OpenGate, { barcode, baseUrl, openGateType, timeoutMs: timeoutMs });
  }

  gateOpenComplete(enteredInfo: any): void {
    this.sendMessage(MessageType.OpenGateComplete, enteredInfo);
  }

  gateOpenStop(barcode: string, baseUrl: string = ''): void {
    this.sendMessage(MessageType.OpenGateStop, { barcode, baseUrl });
  }

  onGateOpenComplete(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.OpenGateComplete);
  }

  toBasket(): void {
    this.sendMessage(MessageType.ToBasket);
  }

  addProductsComplete(): void {
    this.sendMessage(MessageType.AddProductsComplete);
  }

  onAddProductsComplete(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.AddProductsComplete);
  }

  toDisplayModePayment(abortPayment: boolean): void {
    this.sendMessage(MessageType.ToDisplayModePayment, abortPayment);
  }

  toDisplayModeCashlessPayment(abortCashlessPayment: boolean): void {
    this.sendMessage(MessageType.ToDisplayModeCashlessPayment, abortCashlessPayment);
  }

  displayModePaymentComplete(complite: boolean): void {
    this.sendMessage(MessageType.DisplayModePaymentComplete, complite);
  }

  onDisplayModePaymentComplete(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.DisplayModePaymentComplete);
  }

  displayModeCashlessPaymentComplete(complite: boolean): void {
    this.sendMessage(MessageType.DisplayModeCashlessPaymentComplete, complite);
  }

  onDisplayModeCashlessPaymentComplete(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.DisplayModeCashlessPaymentComplete);
  }

  displayModeRefundComplete(paymentFailed: boolean): void {
    this.sendMessage(MessageType.DisplayModeRefundComplete, paymentFailed);
  }

  onDisplayModeRefundComplete(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.DisplayModeRefundComplete);
  }

  onBackButtonClickSubscribe(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.ButtonBackClicked);
  }

  onVisualItemLeafSelectedSubscribe(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.VisualItemLeafSelected);
  }

  machineInactivityChangeTracking(machineInactivity: MachineInactivity): void {
    this.eventMachineInactivityChangeTracking.emit(machineInactivity);
  }

  get creditCardTerminalSimulatorService(): CreditCardTerminalSimulatorService {
    return this.internalCreditCardTerminalSimulatorService;
  }

  get creditCardTerminalState(): CreditCardTerminalState {
    return this.creditCardTerminalSimulatorService.creditCardTerminalState;
  }

  languageChanged(): void {
    this.sendMessage(MessageType.LanguageChanged);
  }

  externalUseCompleted(): void {
    this.sendMessage(MessageType.ExternalUseCompleted);
  }

  private sendMessage(messageType: MessageType, info: any = null): void {
    this.messageService.sendMessage(new Message(messageType, info));
  }

  private dispatch(type: any, payload: any = null): void {
    this.store.dispatch({ type, payload });
  }

  private subscribe(
    f: (x: Message) => void,
    ...messageTypes: MessageType[]
  ): Subscription {
    return this.messageService
      .getSubscription()
      .pipe(filter((x: Message) => messageTypes.indexOf(x.messageType) > -1))
      .subscribe(f);
  }
}
