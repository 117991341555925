import { VuState } from '../lib/lib';
export class NonOperationalState {
  vuState = new VuState();
  isConnected = false;

  static equals(a: NonOperationalState, b: NonOperationalState): boolean {
    if (a === b) { return true; }
    if (a == null || b == null) { return false; }
    if (a.isConnected !== b.isConnected) { return false; }
    return a.vuState.equals(b.vuState);
  }

  equals(b: NonOperationalState): boolean {
    return NonOperationalState.equals(this, b);
  }

  constructor(vuState: VuState = new VuState(), isConnected = false) {
    this.vuState = vuState;
    this.isConnected = isConnected;
  }

  get isNonOperational(): boolean {
    return !this.isConnected || this.vuState.isOutOfOrder;
  }

  fromConnected(isConnected: boolean): NonOperationalState {
    return new NonOperationalState(this.vuState, isConnected);
  }

  fromVuState(vuState: VuState): NonOperationalState {
    return new NonOperationalState(vuState, this.isConnected);
  }

  toString(): string {
    return `isConnected: '${this.isConnected}'. ${this.vuState}`;
  }
}
