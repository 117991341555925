import { Injectable } from '@angular/core';
import { SignalR, BroadcastEventListener, SignalRConnection, ConnectionStatus } from 'ng2-signalr';
import { VuConnectionBase } from './vu-connection-base.service';
import { Money, Ticket, VuState, OrderSaveResult, PrintTaskResult } from '../../../lib/lib';
import { CreditCardTerminalEvent } from '../../../lib/credit-card/credit-card-terminal-event';
import { CIOBoardEvent } from '../../../lib/cioboard/coiboard-event';
import { CardDispenserActionResult } from '../../../lib/rfid-card/card-dispenser-action-result';
import { BluecodeEvent } from '../../../lib/bluecode/bluecode-event';
import { GiftCardEvent } from 'src/app/lib/gift-card/gift-card-event';


@Injectable()
export class SignalRService extends VuConnectionBase {
  private signalR: SignalR;
  private reconnectTimeout = 10000;
  private connection: SignalRConnection;

  protected init(): void {
    const scope = this;
    this.signalR = this.injector.get(SignalR);
    setTimeout(() => scope._connect(), 1); // Let it be created before using the LoggingService
    super.init();
  }

  private _connect(): void {
    const scope = this;

    this.log.info('SignalRService. Starting...');

    this.connection = this.signalR.createConnection();

    this.connection.status.subscribe((status: ConnectionStatus) => {

      scope.isConneсted = status.name === 'connected';

      scope.eventConnectionChanged.emit(scope.isConneсted);

      let statusMessage = `SignalR connection status: ${status.name}`;
      this.log.info(statusMessage);
      statusMessage = `SignalR connection status: %c${status.name}`;

      switch (status.name) {
        case 'connected':
          console.log(statusMessage, 'color: green');
          break;
        case 'connecting':
          console.log(statusMessage, 'color: orange');
          break;
        case 'disconnected':
          console.log(statusMessage, 'color: red');
          setTimeout(() => {
            this.tryConnect();
          }, this.reconnectTimeout);
          break;
        default:
          // console.log(statusMessage);
          break;
      }
    });

    const vuStateChangedEvent = new BroadcastEventListener<VuState>('vuStateChanged');
    this.connection.listen(vuStateChangedEvent);
    vuStateChangedEvent.subscribe((state: VuState) => {
      state = VuState.fromOther(state);
      this.log.info(`vuStateChanged event: ${state}`);
      this.eventVuStateChanged.emit(state);
    });

    const navigatePageEvent = new BroadcastEventListener<string>('navigatepage');
    this.connection.listen(navigatePageEvent);
    navigatePageEvent.subscribe((navigateUrl: string) => {
      this.navigationUrlChanged.emit(navigateUrl);
      this.log.info(`Navigate to: ${navigateUrl}`);
    });

    const scanTicketEvent = new BroadcastEventListener<any>('scancard');
    this.connection.listen(scanTicketEvent);
    scanTicketEvent.subscribe((ticket: any) => {
      const t = Ticket.fromJson(ticket);
      super.scanTicket(t);
    });

    const barcodeReadEvent = new BroadcastEventListener<string>('barcodeRead');
    this.connection.listen(barcodeReadEvent);
    barcodeReadEvent.subscribe((barcode: string) => {
      super.barcodeRead(barcode);
    });

    const rfidCardReadEvent = new BroadcastEventListener<string>('rfidCardRead');
    this.connection.listen(rfidCardReadEvent);
    rfidCardReadEvent.subscribe((rfidCardCode: string) => {
      super.rfidCardRead(rfidCardCode);
    });

    const eventMoneyChanged = new BroadcastEventListener<Money>('moneyChanged');
    this.connection.listen(eventMoneyChanged);
    eventMoneyChanged.subscribe((money: Money) => {
      this.eventMoneyChanged.emit(new Money(money.value, money.currencyCode));
      this.log.info(`moneyChanged event: ${JSON.stringify(money)}`);
    });

    const eventReturnAmountFinished = new BroadcastEventListener<Money>('returnAmountFinished');
    this.connection.listen(eventReturnAmountFinished);
    eventReturnAmountFinished.subscribe(() => {
      super.returnAmountFinished();
    });

    const eventReturnChangeFinished = new BroadcastEventListener<Money>('returnChangeFinished');
    this.connection.listen(eventReturnChangeFinished);
    eventReturnChangeFinished.subscribe(() => {
      super.returnChangeFinished();
    });

    const eventSaveOrderResultReceived = new BroadcastEventListener<OrderSaveResult>('saveOrderResult');
    this.connection.listen(eventSaveOrderResultReceived);
    eventSaveOrderResultReceived.subscribe((saveOrderResult: OrderSaveResult) => {
      const result = OrderSaveResult.fromOther(saveOrderResult);
      this.eventOrderSaveResultReceived.emit(result);
      this.log.info(`eventSaveOrderResultReceived: ${JSON.stringify(saveOrderResult)}`);
    });

    const eventResetOrderRfidCardAccessDataResultReceived =
      new BroadcastEventListener<OrderSaveResult>('resetOrderRfidCardAccessDataResult');
    this.connection.listen(eventResetOrderRfidCardAccessDataResultReceived);
    eventResetOrderRfidCardAccessDataResultReceived.subscribe((saveOrderResult: OrderSaveResult) => {
      const result = OrderSaveResult.fromOther(saveOrderResult);
      this.eventResetOrderRfidCardAccessDataResultReceived.emit(result);
      this.log.info(`eventResetOrderRfidCardAccessDataResultReceived: ${JSON.stringify(saveOrderResult)}`);
    });

    const eventPrintTaskResultReceived = new BroadcastEventListener<PrintTaskResult>('printTaskResult');
    this.connection.listen(eventPrintTaskResultReceived);
    eventPrintTaskResultReceived.subscribe((printTaskResult: PrintTaskResult) => {
      const result = PrintTaskResult.fromOther(printTaskResult);
      this.eventPrintTaskResultReceived.emit(result);
      this.log.info(`eventPrintTaskResultReceived: ${JSON.stringify(printTaskResult)}`);
    });

    const eventTicketRemoved = new BroadcastEventListener<PrintTaskResult>('onTicketRemoved');
    this.connection.listen(eventTicketRemoved);
    eventTicketRemoved.subscribe((printTaskResult: PrintTaskResult) => {
      const result = PrintTaskResult.fromOther(printTaskResult);
      this.eventPrintTicketRemoved.emit(result);
      this.log.info(`eventTicketRemoved: ${JSON.stringify(printTaskResult)}`);
    });

    const eventVuConfigurationChanged = new BroadcastEventListener<any>('vuConfigurationChanged');
    this.connection.listen(eventVuConfigurationChanged);
    eventVuConfigurationChanged.subscribe(() => {
      super.vuConfigurationChanged();
    });

    const eventOnTurnstileEnter = new BroadcastEventListener<Money>('onTurnstileEnter');
    this.connection.listen(eventOnTurnstileEnter);
    eventOnTurnstileEnter.subscribe(() => {
      super.onTurnstileEnter();
    });

    const eventRfidCardProduced = new BroadcastEventListener<CardDispenserActionResult>('rfidCardProduced');
    this.connection.listen(eventRfidCardProduced);
    eventRfidCardProduced.subscribe((x: CardDispenserActionResult) => {
      super.rfidCardProduced(x);
    });

    const eventRfidCardReleased = new BroadcastEventListener<CardDispenserActionResult>('rfidCardReleased');
    this.connection.listen(eventRfidCardReleased);
    eventRfidCardReleased.subscribe((x: CardDispenserActionResult) => {
      super.rfidCardReleased(x);
    });

    const eventRfidCardTaken = new BroadcastEventListener<CardDispenserActionResult>('rfidCardTaken');
    this.connection.listen(eventRfidCardTaken);
    eventRfidCardTaken.subscribe((x: CardDispenserActionResult) => {
      super.rfidCardTaken(x);
    });

    const eventRfidCardCaptured = new BroadcastEventListener<CardDispenserActionResult>('rfidCardCaptured');
    this.connection.listen(eventRfidCardCaptured);
    eventRfidCardCaptured.subscribe((x: CardDispenserActionResult) => {
      super.rfidCardCaptured(x);
    });

    const eventRfidCardRemoved = new BroadcastEventListener<CardDispenserActionResult>('rfidCardRemoved');
    this.connection.listen(eventRfidCardRemoved);
    eventRfidCardRemoved.subscribe((x: CardDispenserActionResult) => {
      super.rfidCardRemoved(x);
    });

    const eventWriteCardDataCompleted = new BroadcastEventListener<CardDispenserActionResult>('writeCardDataCompleted');
    this.connection.listen(eventWriteCardDataCompleted);
    eventWriteCardDataCompleted.subscribe((x: CardDispenserActionResult) => {
      super.writeCardDataCompleted(x);
    });

    const eventRfidCardTransactionStarted = new BroadcastEventListener<CardDispenserActionResult>('rfidCardTransactionStarted');
    this.connection.listen(eventRfidCardTransactionStarted);
    eventRfidCardTransactionStarted.subscribe((x: CardDispenserActionResult) => {
      super.rfidCardTransactionStarted(x);
    });

    const eventRfidCardAvailabilityChanged = new BroadcastEventListener<CardDispenserActionResult>('rfidCardAvailabilityChanged');
    this.connection.listen(eventRfidCardAvailabilityChanged);
    eventRfidCardAvailabilityChanged.subscribe((x: CardDispenserActionResult) => {
      super.rfidCardAvailabilityChanged(x);
    });

    const eventOnTicketRemoved = new BroadcastEventListener('onTicketRemoved');
    this.connection.listen(eventOnTicketRemoved);
    eventOnTicketRemoved.subscribe(() => {
      super.onTicketRemoved();
    });

    const eventOnInvalidRfidCardInserted = new BroadcastEventListener('onInvalidRfidCardInserted');
    this.connection.listen(eventOnInvalidRfidCardInserted);
    eventOnInvalidRfidCardInserted.subscribe(() => {
      super.onInvalidRfidCardInserted();
    });

    const eventCreditCardTerminalEvent = new BroadcastEventListener<any>('onCreditCardTerminalEvent');
    this.connection.listen(eventCreditCardTerminalEvent);
    eventCreditCardTerminalEvent.subscribe((x: any) => {
      const creditCardTerminalEvent = CreditCardTerminalEvent.fromOther(x);
      super.onCreditCardTerminalEvent(creditCardTerminalEvent);
    });

    const eventBluecodeEvent = new BroadcastEventListener<any>('onBluecodeEvent');
    this.connection.listen(eventBluecodeEvent);
    eventBluecodeEvent.subscribe((x: any) => {
      const bluecodeEvent = BluecodeEvent.fromOther(x);
      super.onBluecodeEvent(bluecodeEvent);
    });

    const eventGiftCardEvent = new BroadcastEventListener<any>('onGiftCardEvent');
    this.connection.listen(eventGiftCardEvent);
    eventGiftCardEvent.subscribe((x: any) => {
      const giftCard = GiftCardEvent.fromOther(x);
      super.onGiftCardEvent(giftCard);
    });

    const cashlessEvent = new BroadcastEventListener<any>('onCashlessEvent');
    this.connection.listen(cashlessEvent);
    cashlessEvent.subscribe((x: any) => {
      super.onCashlessEvent(x); // bool
    });

    const eventGateTransactionBegin = new BroadcastEventListener('onGateTransactionBegin');
    this.connection.listen(eventGateTransactionBegin);
    eventGateTransactionBegin.subscribe((x: boolean) => {
      super.onGateTransactionBegin(x);
    });

    const eventGateTransactionEnd = new BroadcastEventListener('onGateTransactionEnd');
    this.connection.listen(eventGateTransactionEnd);
    eventGateTransactionEnd.subscribe((x: boolean) => {
      super.onGateTransactionEnd(x);
    });

    const eventBnaValidationStarted = new BroadcastEventListener('onBnaValidationStarted');
    this.connection.listen(eventBnaValidationStarted);
    eventBnaValidationStarted.subscribe(() => {
      super.onBnaValidationStarted();
    });

    const eventBnaValidationFinished = new BroadcastEventListener('onBnaValidationFinished');
    this.connection.listen(eventBnaValidationFinished);
    eventBnaValidationFinished.subscribe(() => {
      super.onBnaValidationFinished();
    });

    const eventCIOBoardStateChanged = new BroadcastEventListener('onCIOBoardStateChanged');
    this.connection.listen(eventCIOBoardStateChanged);
    eventCIOBoardStateChanged.subscribe((x: any) => {
      const cioBoardEvent = CIOBoardEvent.fromOther(x);
      super.onCIOBoardStateChanged(cioBoardEvent);
    });

    const moneyExchangePayin = new BroadcastEventListener<Money>('moneyExchangePayin');
    this.connection.listen(moneyExchangePayin);
    moneyExchangePayin.subscribe((result: any) => {
      const money = Money.fromJSON(result);
      this.eventMoneyExchangePayin.emit(money);
      this.log.info(`moneyExchangePayin event: ${money}`);
    });

    const moneyExchangePayout = new BroadcastEventListener<Money>('moneyExchangePayout');
    this.connection.listen(moneyExchangePayout);
    moneyExchangePayout.subscribe((result: any) => {
      const money = Money.fromJSON(result);
      this.eventMoneyExchangePayout.emit(money);
      this.log.info(`moneyExchangePayout event: ${money}`);
    });

    const moneyExchangeReadyToMoneyExchange = new BroadcastEventListener<Money>('moneyExchangeReadyToMoneyExchange');
    this.connection.listen(moneyExchangeReadyToMoneyExchange);
    moneyExchangeReadyToMoneyExchange.subscribe((result: any) => {
      super.moneyExchangeReadyToMoneyExchange();
    });

    this.connection.errors.subscribe(error => {
      this.log.error(error.message || error);
    });

    this.tryConnect();
  }

  private tryConnect(): void {
    try {
      this.connection.start().catch(error => {
        this.log.error(error);
      });
    } catch (error) {
      this.log.error(error);
    }
  }

}
