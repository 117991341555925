import { Injectable } from '@angular/core';
import { BaseGridWorkflowService } from '../../../base/base-grid-workflow.service';
import { SaleService } from '../../../../../sale.service';
import { DisplayItem } from '../../../../../../lib/display/display-item/display-item';
import { DisplayItemTypeEnum } from '../../../../../../lib/display/display-item/display-item-type.enum';

@Injectable()
export class CashRefundConfirmationGridWorkflowService extends BaseGridWorkflowService {

  private saleService: SaleService;

  init(
  ) {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.dispatcherService.onDisplayModeRefundComplete((message) => {
      if (!this.displayItem) {
        return;
      }

      this.refundComplete(message && !!message.info);
    });
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CashRefundConfirmation;
  }

  refundComplete(paymentFailed: boolean) {
    if (this.displayItem) {
      let nextItem = this.displayItem.navigationItem;
      if (paymentFailed && this.displayItem.failureItem) {
        nextItem = this.displayItem.failureItem;
      }

      if (nextItem) {
        this.showDisplayItem(nextItem);
      } else {
        this.navigateToRootPage();
      }
    }
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);

    const depositProduct = context.depositProduct;
    const cardInformation = context.cardInformation;

    const refundAmount = context.refundAmount;
    const refundProduct = context.refundProduct;
    if (!refundAmount || !refundProduct) {
      this.navigateToRootPage();
      return;
    }

    const rfidCardCode = this.getRfidCardCodeFormattedData(context);
    this.saleService.ticketReturnAmount(refundProduct, refundAmount, 0, rfidCardCode, true, depositProduct, cardInformation);
  }

  getRfidCardCodeFormattedData(context: any): string {
    let result = '';
    if (!context) {
      return result;
    }

    result += `${context.rfidCardCode}`;

    if (context.odooContext && context.odooContext.externalContext && context.odooContext.externalContext.PersonId) {
      result += `#${context.odooContext.externalContext.PersonId}`;
    }

    return result;
  }
}
