import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { VUTranslateModule } from '../vu-translate/vu-translate.module';
import { ComponentsModule } from '../components/components.module';
import { WorkflowComponent } from '../../components/workflow/workflow/workflow.component';
import { WorkflowStepStateComponent } from '../../components/workflow/workflow-step-state/workflow-step-state.component';
import { TeaserComponent } from '../../components/general/teaser/teaser.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WorkflowSimpleComponent } from 'src/app/components/workflow/workflow-simple/workflow-simple.component';


@NgModule({
  declarations: [
    WorkflowComponent,
    WorkflowStepStateComponent,
    TeaserComponent,
    WorkflowSimpleComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    VUTranslateModule,
    ComponentsModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    WorkflowComponent,
    WorkflowSimpleComponent,
  ]
})
export class WorkflowModule { }
