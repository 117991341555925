import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CustomShopInformation } from 'src/app/lib/custom-shop/custom-shop.information';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { DispatcherService } from '../../services/dispatcher.service';
import { MoneyExchangeStateService } from '../../services/money-exchange/money-exchange-state.service';

@Component({
  selector: 'root',
  templateUrl: './root.component.html',
  styleUrls: ['root.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RootComponent implements OnInit, OnDestroy {
  backButtonVisible: boolean;
  abortButtonVisible: boolean;

  constructor(
    private dispatcherService: DispatcherService,
    private moneyExchangeStateService: MoneyExchangeStateService,
    private configurationService: ConfigurationService,
  ) {
  }

  onSaleButtonClick(): void {
    this.dispatcherService.onButtonSaleShopClick();
  }

  onInfoButtonClick(): void {
    this.dispatcherService.onButtonInfoClick();
  }

  get isTicketVisibleButton(): boolean {
    return this.configurationService.configuration.originalConfigurationHelper?.getBoolean('show_ticket_info_button');
  }

  onTicketButtonClick(): void {
    this.dispatcherService.onButtonTicketClick();
  }

  get isMoneyExchangeVisibleButton(): boolean {
    return this.moneyExchangeStateService.isEnabled;
  }

  onMoneyExchangeButtonClick(): void {
    this.dispatcherService.onButtonMoneyExchangeClick();
  }

  ngOnInit(): void {
    this.dispatcherService.pageHeaderSet('Please select an item');
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = false;
    this.abortButtonVisible = this.dispatcherService.isAbortButtonVisible;
    this.dispatcherService.isAbortButtonVisible = false;
  }

  ngOnDestroy(): void {
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
    this.dispatcherService.isAbortButtonVisible = this.abortButtonVisible;
  }

  get isRestoreCardVisibleButton(): boolean {
    return false;
    // return !!this.configurationService.configuration.lostCardConfiguration;
  }

  onRestoreCardButtonClick(): void {
    this.dispatcherService.onButtonCustomSaleShopClick(
      new CustomShopInformation('/card-dispenser-restore-card', '/card-dispenser-restore-card/restore', true));
  }

  onCardDetailInformationButtonClick(): void {
    this.dispatcherService.onButtonCustomSaleShopClick(
      new CustomShopInformation('/card-detail-information')
    );
  }

  get isCardDetailInformationVisibleButton(): boolean {
    return false;
    // return this.configurationService.configuration.originalConfigurationHelper?.getBoolean('show_lost_card_management_button');
  }
}

