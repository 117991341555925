import { Injectable } from '@angular/core';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { GlobalSettings, Money, Order, Product } from '../../../../../lib/lib';
import { from, Observable, of, Subscription } from 'rxjs';
import { CardDispenserBaseGridWorkflowService } from './card-dispenser-base-grid-workflow.service';
import { CardInformation } from 'src/app/modules/recharge-card/models/card-information';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { SaleService } from 'src/app/services/sale.service';

@Injectable()
export class GiftCardDispenserRefundGridWorkflowService extends CardDispenserBaseGridWorkflowService {

  private _subscription: Subscription;
  private readonly _returnCardConfirmationUniqueName = 'return_card_confirmation';

  private payoutProduct: Product;
  private depositProduct: Product;
  private canCaptureCardPreviousState: boolean;

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.GiftCardDispenserRefund;
  }

  init(
  ) {
    super.init();
  }

  show(displayItem: DisplayItem, context: any) {
    this.canCaptureCardPreviousState = this.cardDispenserService.canCaptureCard;
    super.show(displayItem, context);

    if (!context?.odooContext?.externalContext?.cardInformation) {
      return;
    }

    const cardInformation = CardInformation.createFromAny(context.odooContext.externalContext.cardInformation);
    if (!cardInformation || !cardInformation?.payoutProductId) {
      return;
    }

    const amount = cardInformation?.balance;
    const refundAmount = new Money(amount, GlobalSettings.getCurrencyCode());
    let totalAmount = refundAmount;

    this.displayGridWorkflowService.updateContext({
      refundAmount,
      totalRefundAmount: Money.empty,
    });

    if (this._unsubscribeSubscription) {
      this._unsubscribeSubscription();
    }

    this._subscription = this.getPayoutAndDepositCardProductsByCardInformation(cardInformation).pipe(
      switchMap((products: Product[]) => {
        if (products) {
          this.depositProduct = products.find(item => item.id === cardInformation.depositProductId);
          this.payoutProduct = products.find(item => item.id === cardInformation.payoutProductId);
          if (!this.payoutProduct) {
            return of(false);
          }
          let payoutAmount = Money.empty;
          if (totalAmount && this.depositProduct) {
            totalAmount = totalAmount.add(this.depositProduct.price);
          }
          return from(this.vuCommunicationService.vuHttp.howMuchCanPayoutAmount(totalAmount)).pipe(
            switchMap((canBePaidOut: Money) => {
              if (canBePaidOut) {
                payoutAmount = canBePaidOut;
                if (this.depositProduct && this.depositProduct.price.isPositive) {
                  payoutAmount = canBePaidOut.distract(this.depositProduct.price);
                  if (payoutAmount.isNegative) {
                    return of(false);
                  }
                }
                this.displayGridWorkflowService.updateContext({
                  refundAmount: payoutAmount,
                  refundProduct: this.payoutProduct,
                  depositProduct: this.depositProduct,
                  totalRefundAmount: totalAmount,
                  cardInformation: cardInformation,
                });
                return of(true);
              }
              return of(false);
            }),
          )
        }
        return of(false);
      }),
      tap((result: boolean) => {
        if (result || !cardInformation.allowPayout) {
          this.cardDispenserService.canCaptureCard = cardInformation.isAllowedCaptureCard;
          this.displayGridWorkflowService.changeIsDisabledState(this._returnCardConfirmationUniqueName, false);
          return;
        }
        this.displayGridWorkflowService.changeIsDisabledState(this._returnCardConfirmationUniqueName, true);
      })
    ).subscribe();
  }

  hide(displayItem: DisplayItem, context: any) {
    super.hide(displayItem, context);
    this.resetData();
    this._unsubscribeSubscription();
  }

  private resetData(): void {
    this.cardDispenserService.canCaptureCard = this.canCaptureCardPreviousState;
    this.payoutProduct = null;
    this.depositProduct = null;
  }

  fillDisplayItemRenderData(displayItem: DisplayItem, data: Map<string, any>, context: any) {
    super.fillDisplayItemRenderData(displayItem, data, context);

    if (!displayItem || displayItem.type !== DisplayItemTypeEnum.GiftCardDispenserRefund) {
      return;
    }

    data.set('@ReturnProducts', this._getSubProductDisplayInformation());
    data.set('@TotalRefundAmount', context.totalRefundAmount);
  }

  private _getSubProductDisplayInformation(): string {
    let result = '';

    if (this.depositProduct && this.depositProduct?.price.isPositive) {
      result += `<p>${this.depositProduct.name}: ${this.depositProduct?.price}</p>`;
    }

    return result;
  }

  private getPayoutAndDepositCardProductsByCardInformation(cardInfo: CardInformation): Observable<Product[] | null> {

    const productIds = [];
    if (cardInfo?.depositProductId) {
      productIds.push(cardInfo.depositProductId);
    }

    if (cardInfo?.payoutProductId) {
      productIds.push(cardInfo?.payoutProductId);
    }

    if (!productIds.length) {
      return of(null);
    }

    return from(this.vuCommunicationService.vuHttp.getProductsByIds(productIds)).pipe(
      catchError(error => {
        return of(null);
      }),
    );
  }

  private _unsubscribeSubscription(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }
}
