import { Injectable, Injector } from '@angular/core';
import 'rxjs';
import {
  VisualItem,
  Money,
  AcceptedCash,
  Order,
  Product,
  VuState,
  PrintTask,
  DtoVuConfiguration,
  DtoTicketUse,
  TicketUse,
  CreditCard,
  Ticket,
  PaymentMethod
} from '../../../lib/lib';
import { IVuHttp, IVuHttpSimulator } from './vu-http.interface';
import { LoggingService } from '../../logging/logging.service';
import { ScreenSaverConfiguration } from '../../../lib/screen-saver-configuration';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { TouchTileColor } from '../../../lib/touch-tile/touch-tile-color';
import { TouchTileSwitch } from '../../../lib/touch-tile/touch-tile-switch';
import { RunningLightScenario } from '../../../lib/touch-tile/running-light-scenario';
import { CreditCardTerminalState } from '../../../lib/credit-card/credit-card-terminal-state';
import { DisplayConfiguration } from '../../../lib/display/configuration/display-configuration';
import { CIOBoardPinEvent } from '../../../lib/cioboard/cioboard-pin-event';
import { MoneyExchangeRules } from '../../../lib/money-exchange/money-exchange-rules';
import { FmcuState } from 'src/app/modules/fmcu-access/models/fmcu-state';
import { CardInformation } from 'src/app/modules/recharge-card/models/card-information';
import { CardDispenserStatus } from 'src/app/lib/rfid-card/card-dispenser-status';
import { tap } from 'rxjs/operators';
import { RfidCardData } from 'src/app/lib/card-dispenser/rfid-card-data';

@Injectable()
export abstract class VuHttpBaseService implements IVuHttp, IVuHttpSimulator {
  protected _useProductionApi = true;
  protected _log: LoggingService;
  constructor(
    protected injector: Injector,
  ) {
    this.init();
  }

  protected abstract init(): void;

  abstract getProducts(): Promise<Product[]>;
  abstract getProductsByIds(productIds: number[]): Promise<Product[]>;
  abstract getProduct(productId: number): Promise<Product>;
  abstract getSubProduct(productId: number): Promise<Product>;
  abstract calculateProductsPrice(productsInfo: any, priceListId: number): Promise<number[]>;
  abstract getVisualItems(type: string): Promise<VisualItem[]>;
  abstract getDisplayConfiguration(configurationId: number): Promise<DisplayConfiguration>;
  abstract getAcceptedCash(amount: Money): Promise<AcceptedCash>;
  abstract getAcceptedCreditCards(): Promise<CreditCard[]>;
  abstract getVuState(): Promise<VuState>;
  abstract getVuConfiguration(): Promise<DtoVuConfiguration>;
  abstract getTicketUse(code: string): Promise<TicketUse[]>;
  abstract canPayoutAmount(amount: Money): Promise<boolean>;
  abstract howMuchCanPayoutAmount(amount: Money): Promise<Money>;
  abstract updateAngularPingState(): Promise<boolean>;
  abstract getMoneyExchangeRules(): Observable<MoneyExchangeRules>;

  print(task: PrintTask): Promise<any> {
    const m = `VuHttpBaseService. print. ${task}`;
    this.log.info(m);
    return this.returnEmptyPromise();
  }

  printByTemplateTypeUniqueName(templateTypeUniqueName: string, language: string): Promise<any> {
    const m = `VuHttpBaseService. printTemplateTypeUniqueName. templateTypeUniqueName: ${templateTypeUniqueName}, language ${language}`;
    this.log.info(m);
    return this.returnEmptyPromise();
  }

  printApi(): Promise<any> {
    const m = `VuHttpBaseService. printApi.`;
    this.log.info(m);
    return this.returnEmptyPromise();
  }

  openFmcuApi(url: string, body: object): Promise<any> {
    const m = `VuHttpBaseService. openFmcuApi. url:${url}. body:${JSON.stringify(body)}`;
    this.log.info(m);
    return this.returnEmptyPromise();
  }

  // beginCardPaymentTransaction(amount: Money) {
  //   this.log.info(`VuHttpBaseService. beginCardPaymentTransaction. amount:${amount}`);
  // }

  beginPaymentTransaction(amount: Money, paymentMethod: PaymentMethod, giftName: string, giftAmount: Money): Promise<void> {
    this.log.info(`VuHttpBaseService. beginPaymentTransaction. amount:${amount}, paymentMethod: ${paymentMethod}, giftName:${giftName}, giftAmount: ${giftAmount}`);
    return this.returnEmptyPromise();
  }

  commitPaymentTransaction(force: boolean): Promise<void> {
    this.log.info(`VuHttpBaseService. commitPaymentTransaction. force:${force}`);
    return this.returnEmptyPromise();
  }

  appendTransactionInfo(info: string): Promise<void> {
    this.log.info(`VuHttpBaseService. appendTransactionInfo. ${info}`);
    return this.returnEmptyPromise();
  }

  revertPaymentTransaction(allowRefundAngular: boolean): Promise<any> {
    this.log.info(`VuHttpBaseService. revertPaymentTransaction. allowRefundAngular: ${allowRefundAngular}`);
    return this.returnEmptyPromise();
  }

  revertCashlessTransaction(paymentMethod: PaymentMethod): Promise<any> {
    this.log.info(`VuHttpBaseService. revertCashlessTransaction. paymentMethod: ${paymentMethod}`);
    return this.returnEmptyPromise();
  }

  abortCashlessTransaction(paymentMethod: PaymentMethod): Promise<any> {
    this.log.info(`VuHttpBaseService. abortCashlessTransaction. paymentMethod: ${paymentMethod}`);
    return this.returnEmptyPromise();
  }

  returnAmount(amount: Money): Promise<any> {
    this.log.info(`VuHttpBaseService. returnAmount: ${amount}`);
    return this.returnEmptyPromise();
  }

  barcodeLedEnable(value: boolean): void {
    this.log.info(`VuHttpBaseService. barcodeLedEnable: ${value}`);
  }

  rfidCardLedEnable(value: boolean): void {
    this.log.info(`VuHttpBaseService. rfidCardLedEnable: ${value}`);
  }

  rfidCardReaderEnable(value: boolean): void {
    this.log.info(`VuHttpBaseService. rfidCardReaderEnable: ${value}`);
  }

  saveOrder(order: Order): Promise<any> {
    this.log.info(`VuHttpBaseService. saveOrder: ${order}`);
    return this.returnEmptyPromise();
  }

  cancelOrder(order: Order): Promise<any> {
    this.log.info(`VuHttpBaseService. cancelOrder: ${order}`);
    return this.returnEmptyPromise();
  }

  resetOrderRfidCardAccessData(orderId: number, cardNumber: string): Promise<any> {
    this.log.info(`VuHttpBaseService. resetOrderRfidCardAccessData: orderId: ${orderId}, cardNumber: ${cardNumber}`);
    return this.returnEmptyPromise();
  }

  scanTicket(barcode: string): void {
    this.log.info(`VuHttpBaseService. scanTicket: ${barcode}`);
  }

  getTicketInfo(barcode: string): Observable<Ticket> {
    this.log.info(`VuHttpBaseService. getTicketInfo: ${barcode}`);
    return of(null);
  }

  activateOneDayTicket(ticketCode: string): Observable<Ticket> {
    this.log.info(`VuHttpBaseService. activateOneDayTicket: ${ticketCode}`);
    return of(null);
  }

  printTicket(ticketCode: string): Promise<any> {
    this.log.info(`VuHttpBaseService. printTicket: ${ticketCode}`);
    return this.returnEmptyPromise();
  }

  callStaff(): void {
    this.log.info(`VuHttpBaseService. callStaff.`);
  }

  get isUseProductionApi(): boolean {
    return this._useProductionApi;
  }

  set isUseProductionApi(x: boolean) {
    this.log.info(`isUseProductionApi: ${x}`);
    this._useProductionApi = x;
  }

  protected get log(): LoggingService {
    if (!this._log) {
      this._log = this.injector.get(LoggingService);
    }
    return this._log;
  }

  protected parseProducts(json: any): Product[] {
    const result: Product[] = [];
    try {
      for (const item of json) {
        const p = Product.fromJson(item);
        result.push(p);
      }
    } catch (e) {
      this.log.error(`parseProducts. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }
    return result;
  }

  protected parseVisualItems(json: any, type: string): VisualItem[] {
    const visualItems: VisualItem[] = [];
    try {
      for (const jsonItem of json) {
        const item = VisualItem.fromJSONObject(jsonItem, type, this.log);
        if (item != null) {
          visualItems.push(item);
        }
      }
      visualItems.sort((a, b) => a.sequence - b.sequence);
    } catch (e) {
      this.log.error(`parseVisualItems. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }

    return visualItems;
  }

  protected parseDisplayConfiguration(json: any): DisplayConfiguration {

    try {
      if (json == null) {
        return null;
      }
      return DisplayConfiguration.fromOther(json.properties);
    } catch (e) {
      this.log.error(`parseDisplayConfiguration. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }

    return null;
  }

  protected parseAcceptedCash(json: any): AcceptedCash {
    const result = new AcceptedCash([], []);
    try {
      result.coins = Money.toMoneyArray(json.coins);
      result.banknotes = Money.toMoneyArray(json.banknotes);
    } catch (e) {
      this.log.error(`parseVisualItems. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }
    return result;
  }

  protected parseAcceptedCreditCards(json: any): CreditCard[] {
    const result: CreditCard[] = [];
    try {
      for (const item of json) {
        const p = CreditCard.fromJson(item);
        result.push(p);
      }
    } catch (e) {
      this.log.error(`parseTicketUse. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }
    return result;
  }

  protected parseTicketUse(json: any): TicketUse[] {
    const result: TicketUse[] = [];
    try {
      for (const item of json) {
        const p = TicketUse.fromJson(item);
        result.push(p);
      }
    } catch (e) {
      this.log.error(`parseTicketUse. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }
    return result;
  }

  protected handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  protected returnEmptyPromise(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(true);
    });
  }

  getScreenSaverConfiguration(): Promise<ScreenSaverConfiguration> {
    return new Promise<any>((resolve, reject) => {
      resolve(true);
    });
  }

  setScreenSaverConfiguration(screenSaverConfiguration: ScreenSaverConfiguration): void {
  }

  openEnter(): Observable<boolean> {
    this.log.info(`VuHttpBaseService. openEnter`);
    return of(false);
  }

  closeEnter(): Observable<boolean> {
    this.log.info(`VuHttpBaseService. closeEnter`);
    return of(false);
  }

  demoPayment(): Observable<any> {
    return of(false);
  }

  changeTouchTileColor(touchTileColor: TouchTileColor[]): Observable<boolean> {
    this.log.info(`VuHttpBaseService. changeTouchTileColor: ${touchTileColor}`);
    return of(false);
  }

  changeTouchTileSwitch(touchTileSwitch: TouchTileSwitch[]): Observable<boolean> {
    this.log.info(`VuHttpBaseService. changeTouchTileSwitch: ${touchTileSwitch}`);
    return of(false);
  }

  changeTouchTileManualMode(enabled: boolean): Observable<boolean> {
    this.log.info(`VuHttpBaseService. changeTouchTileManualMode: ${enabled}`);
    return of(false);
  }

  switchLntBoard(enabled: boolean): void {
    this.log.info(`VuHttpBaseService. switchLntBoard: ${enabled}`);
  }

  setRunningLight(runningLightScenario: RunningLightScenario): Observable<boolean> {
    this.log.info(`VuHttpBaseService. runningLightScenario: ${runningLightScenario}`);
    return of(false);
  }

  getCreditCardTerminalState(): Observable<CreditCardTerminalState> {
    this.log.info(`VuHttpBaseService. getCreditCardTerminalState`);
    return of(new CreditCardTerminalState());
  }

  gateReadBarcode(barcode: string, baseUrl: string, openGateType: string, timeout: number): Observable<any> {
    this.log.info(`VuHttpBaseService. gateReadBarcode. barcode: ${barcode}, baseUrl: ${baseUrl}, openGateType: ${openGateType}, timeout: ${timeout}`);
    return of(false);
  }

  produceRfidCard(): Promise<any> {
    this.log.info(`VuHttpBaseService. produceRfidCard`);
    return Promise.resolve();
  }

  releaseRfidCard(): Promise<any> {
    this.log.info(`VuHttpBaseService. releaseRfidCard`);
    return Promise.resolve();
  }

  startTakingRfidCard(): Promise<any> {
    this.log.info(`VuHttpBaseService. startTakingRfidCard`);
    return Promise.resolve();
  }

  stopTakingRfidCard(): Promise<any> {
    this.log.info(`VuHttpBaseService. stopTakingRfidCard`);
    return Promise.resolve();
  }

  captureRfidCard(): Promise<any> {
    this.log.info(`VuHttpBaseService. captureRfidCard`);
    return Promise.resolve();
  }

  cardDispenserStatus(): Observable<CardDispenserStatus> {
    return of(null).pipe(
      tap(
        _ => {
          this.log.info(`VuHttpBaseService. cardDispenserStatus`);
        }),
    );
  }

  writeRfidCardData(rfidCardData: RfidCardData): Promise<any> {
    this.log.info(`VuHttpBaseService. writeRfidCardData`);
    return Promise.resolve();
  }

  startTransaction(): Promise<any> {
    this.log.info(`VuHttpBaseService. startTransaction`);
    return Promise.resolve();
  }

  stopTransaction(): Promise<any> {
    this.log.info(`VuHttpBaseService. stopTransaction`);
    return Promise.resolve();
  }

  changeServerLanguage(localeId: string): void {
    this.log.info(`VuHttpBaseService. changeServerLanguage. locale_id: ${localeId}`);
  }

  cioBoardAction(outputPinName: CIOBoardPinEvent[]): Promise<any> {
    this.log.info(`VuHttpBaseService. cioBoardAction. action: ${JSON.stringify(outputPinName)}`);
    return this.returnEmptyPromise();
  }

  writeLogMessages(messages: string[]): Promise<any> {
    return this.returnEmptyPromise();
  }

  isCardTerminalAvailable(): Observable<boolean> {
    this.log.info(`VuHttpBaseService. isCardTerminalAvailable.`);
    return of(false);
  }

  isMoneyExchangeTransactionExist(): Observable<boolean> {
    return of(false);
  }

  beginMoneyExchangeTransaction(acceptedCash: AcceptedCash): Observable<boolean> {
    return of(false);
  }

  commitMoneyExchangeTransaction(): Observable<boolean> {
    return of(false);
  }

  moneyExchangePayoutMoney(payoutAcceptedCash: AcceptedCash): Observable<boolean> {
    return of(false);
  }

  rollbackMoneyExchangeTransaction(): Observable<boolean> {
    return of(false);
  }

  beginUnrestrictedCashPaymentTransaction(): Observable<boolean> {
    return of(false);
  }

  commitUnrestrictedCashPaymentTransaction(): Observable<boolean> {
    return of(false);
  }

  resetUnrestrictedCashPaymentTransaction(): Observable<boolean> {
    return of(false);
  }

  getAcceptedCashWithoutRestrictions(): Observable<AcceptedCash> {
    return of(null);
  }

  getOrderCache(): Observable<AcceptedCash> {
    return of(null);
  }

  getFmcuState(baseUrl: string): Observable<FmcuState> {
    return of(null);
  }

  gateClose(baseUrl: string): Observable<any> {
    return of(null);
  }

  getCardPaymentInformation(code: string): Observable<CardInformation> {
    return of(null);
  }

  cardPaymentScan(code: string, amount: number): Observable<boolean> {
    return of(false);
  }
}
