
import { Injectable, EventEmitter, Injector } from '@angular/core';
import { VuCommunicationService } from '../vu/vu-communication.service';
import { IVuConnection } from '../vu/connection/vu-connection.interfaces';
import { CardDispenserActionResult } from '../../lib/rfid-card/card-dispenser-action-result';
import { CardDispenserCompleteActionResult } from '../../lib/rfid-card/card-dispenser-complete-action-result';
import { LoggingService } from '../logging/logging.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from '../gui/modal/modal-service';
import { MessageModalComponent } from '../../components/modal/message-modal/message-modal.component';
import { CardDispenserState } from '../../lib/card-dispenser-state.enum';
import { ConfigurationService } from '../configuration/configuration.service';
import { CardDispenserStatus } from 'src/app/lib/rfid-card/card-dispenser-status';
import { Observable } from 'rxjs';
import { RfidCardData } from 'src/app/lib/card-dispenser/rfid-card-data';
import { tap } from 'rxjs/operators';

@Injectable()
export class CardDispenserService {
  cardRead: EventEmitter<CardDispenserActionResult> = new EventEmitter<CardDispenserActionResult>();
  cardReadyToTake: EventEmitter<CardDispenserActionResult> = new EventEmitter<CardDispenserActionResult>();
  complete: EventEmitter<CardDispenserCompleteActionResult> = new EventEmitter<CardDispenserCompleteActionResult>();
  availabilityChanged: EventEmitter<CardDispenserActionResult> = new EventEmitter<CardDispenserActionResult>();
  writeCardDataCompleted: EventEmitter<CardDispenserActionResult> = new EventEmitter<CardDispenserActionResult>();

  private operationCardCode: string;

  private loggingService: LoggingService;
  private vuCommunicationService: VuCommunicationService;
  private vuConnection: IVuConnection;
  private configurationService: ConfigurationService;

  private autoCaptureTimeout: any;

  private modalRef: BsModalRef;
  private modalService: ModalService;

  private _state = CardDispenserState.None;

  private _issueCardRetryCount = 0;
  private readonly _defaultIssueCardRetryCount = 3;

  private _isAvailable = true;

  canCaptureCard = false;
  temporaryTakeCard = false;

  constructor(
    protected injector: Injector,
  ) {
    this.loggingService = this.injector.get(LoggingService);

    this.configurationService = this.injector.get(ConfigurationService);

    this.vuCommunicationService = injector.get(VuCommunicationService);
    this.vuConnection = this.vuCommunicationService.vuConnection;

    this.modalService = this.injector.get(ModalService);

    this.vuConnection.eventRfidCardProduced.subscribe((x: CardDispenserActionResult) => this.onRfidCardProduced(x));
    this.vuConnection.eventRfidCardReleased.subscribe((x: CardDispenserActionResult) => this.onRfidCardReleased(x));
    this.vuConnection.eventRfidCardRemoved.subscribe((x: CardDispenserActionResult) => this.onRfidCardRemoved(x));
    this.vuConnection.eventRfidCardCaptured.subscribe((x: CardDispenserActionResult) => this.onRfidCardCaptured(x));
    this.vuConnection.eventRfidCardTaken.subscribe((x: CardDispenserActionResult) => this.onRfidCardTaken(x));
    this.vuConnection.eventRfidCardAvailabilityChanged.subscribe((x: CardDispenserActionResult) => this.onRfidCardAvailabilityChanged(x));
    this.vuConnection.eventWriteCardDataCompleted.subscribe((x: CardDispenserActionResult) => this.onWriteCardDataCompleted(x));
  }


  initialize(): Observable<any> {
    return this.status.pipe(
      tap((status: CardDispenserStatus)=> {
        this._isAvailable = status?.isAvailable || false;
      })
    )
  }

  issueCard(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.operationCardCode) {
        this.loggingService.warning('Card Dispenser Service: issueCard. Card already read');
        reject();
      }

      this._startTransaction().then(
        () => {
          this._issueCardRetryCount = this._defaultIssueCardRetryCount;
          this.state = CardDispenserState.IssueCardTransaction;
          this._produceRfidCard();
          resolve(true);
        }, () => {
          this.loggingService.warning('Card Dispenser Service: issueCard. Transaction not started');
          reject();
        }
      );
    });
  }

  takeCardFromCustomer(temporaryTakeCard = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.temporaryTakeCard = temporaryTakeCard;
      if (this.operationCardCode) {
        this.loggingService.warning('Card Dispenser Service: takeCardFromCustomer. Card already read');
        reject();
      }

      this._startTransaction().then(
        () => {
          this.state = CardDispenserState.TakeCardTransaction;
          this._startTakingRfidCard();
          this.canCaptureCard = false;
          resolve(true);
        },
        () => {
          this.loggingService.warning('Card Dispenser Service: takeCardFromCustomer. Transaction not started');
          reject();
        }
      );
    });
  }

  stopTakeCardFromCustomer(): Promise<boolean> {
    this.temporaryTakeCard = false;
    if (this.operationCardCode) {
      return this.releaseCardCustomer(true);
    } else {
      return this._stopTakingRfidCard().then(
        () => {
          return this.stopTransaction();
        });
    }
  }

  releaseCardCustomer(autoCaptureByTimeOut: boolean = false): Promise<boolean> {
    if (!this.operationCardCode) {
      this.loggingService.warning('Card Dispenser Service: releaseCardCustomer. Card not found');
    }

    if (autoCaptureByTimeOut) {
      this._startAutoCaptureByTimeout('Please take your card');
    }

    return this._releaseRfidCard();
  }

  get isHasTemporaryCard(): boolean {
    return this.temporaryTakeCard && !!this.operationCardCode;
  }

  get isShowWaitRemoveCardDialog(): boolean {
    return !!this.autoCaptureTimeout;
  }

  private _startAutoCaptureByTimeout(message: string): void {
    this._stopAutoCaptureByTimeout();

    this._showWaitRemoveCardMessage(message);

    const autoCaptureTimeoutDelay = this.autoCaptureTimeoutDelay;
    this.loggingService.debug(`Card Dispenser Service: AutoCaptureByTimeout. Start timeout: ${autoCaptureTimeoutDelay} (ms)`);
    this.autoCaptureTimeout = setTimeout(
      () => {
        this.loggingService.debug('Card Dispenser Service: AutoCaptureByTimeout. Timeout');
        this._stopAutoCaptureByTimeout();
        this._stoppingTransaction();
      },
      autoCaptureTimeoutDelay
    );
  }

  private _stopAutoCaptureByTimeout(): void {
    if (!this.autoCaptureTimeout) {
      return;
    }

    clearTimeout(this.autoCaptureTimeout);
    this.autoCaptureTimeout = null;
    this._hideWaitRemoveCardMessage();
  }

  private _showWaitRemoveCardMessage(message: string): void {
    this._showMessageDialog(message);
  }

  private _showMessageDialog(text: string): void {
    if (!this.modalService) {
      return;
    }

    this.modalRef = this.modalService.show(
      MessageModalComponent,
      {
        messages: [text],
      },
      null
    );
  }

  private _closeMessageDialog(): void {
    if (!this.modalService || !this.modalRef) {
      return;
    }
    this.modalService.close(this.modalRef);
    this.modalRef = null;
  }

  private _hideWaitRemoveCardMessage(): void {
    this._closeMessageDialog();
  }

  captureCard(): void {
    if (!this.operationCardCode) {
      this.loggingService.warning('Card Dispenser Service: captureCard. Card not found');
    }

    this.temporaryTakeCard = false;

    this._captureRfidCard();
  }

  get cardCode(): string {
    return this.operationCardCode;
  }

  private onRfidCardProduced(cardDispenserActionResult: CardDispenserActionResult): void {
    if (!cardDispenserActionResult) {
      return;
    }

    this.changeIsAvailable(cardDispenserActionResult.isAvailable);

    if (!this.inTransaction) {
      return;
    }

    if (this.state === CardDispenserState.IssueCardTransaction &&
      cardDispenserActionResult && (cardDispenserActionResult.isFailed || !cardDispenserActionResult.barcode)) {
      this._logEvent('onRfidCardProduced. Invalid Card Produced', cardDispenserActionResult);
      this._captureRfidCard();
      return;
    }

    this.state = CardDispenserState.InTransaction;

    this._logEvent('rfidCardProduced', cardDispenserActionResult);
    this._cardRead(cardDispenserActionResult);
  }

  private onRfidCardReleased(cardDispenserActionResult: CardDispenserActionResult): void {
    if (!cardDispenserActionResult) {
      return;
    }

    this.changeIsAvailable(cardDispenserActionResult.isAvailable);

    if (!this.inTransaction) {
      return;
    }

    this._logEvent('rfidCardReleased', cardDispenserActionResult);
    this.cardReadyToTake.emit(cardDispenserActionResult);
  }

  private onRfidCardRemoved(cardDispenserActionResult: CardDispenserActionResult): void {
    if (!cardDispenserActionResult) {
      return;
    }

    this.changeIsAvailable(cardDispenserActionResult.isAvailable);
    this.loggingService.debug(`Card Dispenser Service: onRfidCardRemoved: ${this.operationCardCode}`);
    this.operationCardCode = null;

    if (!this.inTransaction) {
      if (this.state === CardDispenserState.StartingTransaction) {
        this.state = CardDispenserState.InTransaction;
      }
      return;
    }

    this._logEvent('rfidCardRemoved', cardDispenserActionResult);

    if (this.state === CardDispenserState.IssueCardTransaction) {
      return;
    }

    this._stopAutoCaptureByTimeout();

    if (this.state === CardDispenserState.TakeCardTransaction) {
      this._startTakingRfidCard();
      return;
    }

    this.temporaryTakeCard = false;
    this._complete(cardDispenserActionResult, false);
  }

  private onRfidCardCaptured(cardDispenserActionResult: CardDispenserActionResult): void {
    if (!cardDispenserActionResult) {
      return;
    }

    this.changeIsAvailable(cardDispenserActionResult.isAvailable);
    this.loggingService.debug(`Card Dispenser Service: onRfidCardCaptured: ${this.operationCardCode}`);
    this.operationCardCode = null;

    if (!this.inTransaction) {
      return;
    }

    if (this.state === CardDispenserState.IssueCardTransaction) {
      this._logEvent('rfidCardCaptured. Invalid Card Captured', cardDispenserActionResult);
      this._issueCardRetryCount--;
      if (this._issueCardRetryCount <= 0) {
        this.stopTransaction().finally(() => {
          this._cardRead(new CardDispenserActionResult(false, null, cardDispenserActionResult.isAvailable));
        });
        return;
      }
      setTimeout(
        () => {
          this._produceRfidCard();
        }, 3 * 1000);

      return;
    }

    this._logEvent('rfidCardCaptured', cardDispenserActionResult);
    this._stopAutoCaptureByTimeout();
    this._complete(cardDispenserActionResult, true);
  }

  private onRfidCardTaken(cardDispenserActionResult: CardDispenserActionResult): void {
    if (!cardDispenserActionResult) {
      this._logEvent('rfidCardTaken no result', cardDispenserActionResult);
      return;
    }

    this.changeIsAvailable(cardDispenserActionResult.isAvailable);

    if (!this.inTransaction) {
      this._logEvent('rfidCardTaken not in transaction', cardDispenserActionResult);
      return;
    }

    this._logEvent('rfidCardTaken', cardDispenserActionResult);

    if (this.state === CardDispenserState.TakeCardTransaction &&
      cardDispenserActionResult && (cardDispenserActionResult.isFailed || !cardDispenserActionResult.barcode)) {
      this._startAutoCaptureByTimeout('Failed to read card');
      this._releaseRfidCard();
      return;
    }

    this.state = CardDispenserState.InTransaction;

    this._cardRead(cardDispenserActionResult);
  }

  private onRfidCardAvailabilityChanged(cardDispenserActionResult: CardDispenserActionResult): void {

    if (!cardDispenserActionResult) {
      return;
    }

    this._logEvent('onRfidCardAvailabilityChanged', cardDispenserActionResult);
    this.changeIsAvailable(cardDispenserActionResult.isAvailable);
  }

  private onWriteCardDataCompleted(cardDispenserActionResult: CardDispenserActionResult): void {
    if (!cardDispenserActionResult) {
      return;
    }

    this._logEvent('onRfidCardAvailabilityChanged', cardDispenserActionResult);
    this.changeIsAvailable(cardDispenserActionResult.isAvailable);

    this.writeCardDataCompleted.emit(cardDispenserActionResult);
  }

  releaseCardCustomerAndContinueTakingCardFromCustomer(): void {
    this.state = CardDispenserState.TakeCardTransaction;
    this.releaseCardCustomer(true);
  }

  private _logEvent(eventName: string, cardDispenserActionResult: CardDispenserActionResult): void {
    this.loggingService.debug(
      `Card Dispenser Service: ${eventName}: ${cardDispenserActionResult ? cardDispenserActionResult.toString() : ''}`);
  }

  private _cardRead(cardDispenserActionResult: CardDispenserActionResult): void {

    if (cardDispenserActionResult && !cardDispenserActionResult.isFailed && cardDispenserActionResult.barcode) {
      this.loggingService.debug(`Card Dispenser Service: _cardRead OK: ${this.operationCardCode}`);
      this.operationCardCode = cardDispenserActionResult.barcode;
    } else {
      this.loggingService.debug(`Card Dispenser Service: _cardRead Failed: ${this.operationCardCode}`);
      this.operationCardCode = null;
    }

    this.cardRead.emit(cardDispenserActionResult);
  }

  private _complete(cardDispenserActionResult: CardDispenserActionResult, captured: boolean): void {
    this.stopTransaction().finally(() => {
      const cardDispenserCompleteActionResult = new CardDispenserCompleteActionResult(
        cardDispenserActionResult.isFailed,
        cardDispenserActionResult.barcode,
        cardDispenserActionResult.isAvailable,
        captured
      );

      this.complete.emit(cardDispenserCompleteActionResult);
    });
  }

  private _produceRfidCard(): Promise<any> {
    this.loggingService.debug('Card Dispenser Service: _produceRfidCard');
    return this.vuCommunicationService.vuHttp.produceRfidCard();
  }

  private _releaseRfidCard(): Promise<any> {
    this.loggingService.debug('Card Dispenser Service: _releaseRfidCard');
    return this.vuCommunicationService.vuHttp.releaseRfidCard();
  }

  private _startTakingRfidCard(): Promise<any> {
    this.loggingService.debug('Card Dispenser Service: _startTakingRfidCard');
    return this.vuCommunicationService.vuHttp.startTakingRfidCard();
  }

  private _stopTakingRfidCard(): Promise<any> {
    this.loggingService.debug('Card Dispenser Service: _stopTakingRfidCard');
    return this.vuCommunicationService.vuHttp.stopTakingRfidCard();
  }

  private _captureRfidCard(): Promise<any> {
    this.loggingService.debug('Card Dispenser Service: _captureRfidCard');
    return this.vuCommunicationService.vuHttp.captureRfidCard();
  }

  private _stopTransaction(): Promise<any> {
    this.loggingService.debug('Card Dispenser Service: _stopTransaction');
    return this.vuCommunicationService.vuHttp.stopTransaction();
  }

  private _startTransaction(): Promise<any> {

    return new Promise((resolve, reject) => {

      if (this.state !== CardDispenserState.None) {
        this.loggingService.debug('Card Dispenser Service: startTransaction started');
        resolve(true);
        return;
      }

      const subscription = this.vuConnection.eventRfidCardTransactionStarted.subscribe((x: CardDispenserActionResult) => {
        subscription.unsubscribe();
        if (x.isAvailable) {
          this.state = CardDispenserState.InTransaction;
          this.canCaptureCard = true;
          resolve(true);
        } else {
          this.changeIsAvailable(false);
          reject();
        }
      });

      this.state = CardDispenserState.StartingTransaction;
      this.loggingService.debug('Card Dispenser Service: _startTransaction');
      this.vuCommunicationService.vuHttp.startTransaction();
    });
  }

  private _stoppingTransaction(): Promise<any> {
    if (this.state !== CardDispenserState.InTransaction) {
      this.loggingService.debug('Card Dispenser Service: _stoppingTransaction. Transaction not started');
      return Promise.resolve();
    }

    this.state = CardDispenserState.StoppingTransaction;

    return this._stopTransaction();
  }

  stopTransaction(force: boolean = false): Promise<boolean> {
    if (this.state === CardDispenserState.None && !force) {
      this.loggingService.debug('Card Dispenser Service: stopTransaction. Transaction not started');
      return Promise.resolve(false);
    }

    this.loggingService.debug(
      `Card Dispenser Service: stopTransaction. State: ${this.state}, card: ${this.operationCardCode || 'None'}`
    );

    if (this.state === CardDispenserState.InTransaction ||
      this.state === CardDispenserState.IssueCardTransaction ||
      this.state === CardDispenserState.TakeCardTransaction ||
      force) {
      return this._stopTransaction().finally(() => {
        this.resetState();
        return Promise.resolve(true);
      });
    }
    this.resetState();
    return Promise.resolve(true);
  }

  private resetState(): void {
    this.loggingService.debug(`Card Dispenser Service: resetState: ${this.operationCardCode}`);
    this.state = CardDispenserState.None;
    this.operationCardCode = null;
    this._issueCardRetryCount = 0;
  }

  private get state(): CardDispenserState {
    return this._state;
  }

  private set state(value: CardDispenserState) {
    this.loggingService.debug(`Card Dispenser Service: change state from: '${this._state}' to '${value}'`);
    this._state = value;
  }

  get inTransaction(): boolean {
    return this.state === CardDispenserState.InTransaction ||
      this.state === CardDispenserState.IssueCardTransaction ||
      this.state === CardDispenserState.TakeCardTransaction ||
      this.state === CardDispenserState.StoppingTransaction;
  }

  get autoCaptureTimeoutDelay(): number {
    const defaultDelay = 1000 * 20;
    if (!this.configurationService || !this.configurationService.configuration) {
      return defaultDelay;
    }
    return this.configurationService.configuration.cardDispenserAutoRetractTimeoutMs || defaultDelay;
  }

  get isAvailable(): boolean {
    return this._isAvailable;
  }

  private changeIsAvailable(value: boolean): void {
    if (this.isAvailable === value) {
      return;
    }

    this.loggingService.debug(`Card Dispenser Service: change Is Available: '${this._isAvailable}' to '${value}'`);
    this._isAvailable = value;

    if (this.inTransaction && !value) {
      this.resetState();
    }

    this.availabilityChanged.emit(new CardDispenserActionResult(false, '', value));
  }

  get status(): Observable<CardDispenserStatus> {
    return this.vuCommunicationService.vuHttp.cardDispenserStatus();
  }

  writeRfidCardData(rfidCardData: RfidCardData): Promise<any>{
    return this.vuCommunicationService.vuHttp.writeRfidCardData(rfidCardData);
  }

}
