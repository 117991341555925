import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Money, Order, OrderLine, OrderType, Product } from 'src/app/lib/lib';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { SaleService } from 'src/app/services/sale.service';
import { VuCommunicationService } from 'src/app/services/vu/vu-communication.service';
import { CardInformation } from '../models/card-information';
import { OutstandingOvertimeInformation } from '../models/outstanding-overtime-information';

@Injectable()
export class RechargeCardService {

  constructor(
    private configurationService: ConfigurationService,
    private vuCommunicationService: VuCommunicationService,
    private saleService: SaleService,
  ) { }

  loadCardInformation(cardNumber: string, isSimple: boolean = false, productId: number = 0): Observable<CardInformation> {
    return of(true).pipe(
      switchMap(() => {
        const requestValues = new Map<string, any>();
        requestValues.set('cardNumber', cardNumber);

        if (productId) {
          requestValues.set('productId', productId);
        }

        if (isSimple) {
          requestValues.set('simple', true);
        }

        requestValues.set('is_card', true);

        const paymentControllerUrl = this.configurationService.configuration.odooUrl + '/api/card/information';

        return from(this.vuCommunicationService.ExternalApiService.sendPostRequestOdooJson(paymentControllerUrl, requestValues))
          .pipe(
            catchError(error => {
              return of(false);
            }),
            map(result => {
              if (!result || !result.name) {
                return null;
              }
              return CardInformation.createFromAny(result);
            }),
          );
      }),
    );
  }

  createOrder(cardInformation: CardInformation, managerCardNumber: string = '', customPaymentAmount: number = 0, outstandingOvertimeInformation: OutstandingOvertimeInformation = null): Observable<Order> {
    return from(this.vuCommunicationService.vuHttp
      .getProductsByIds([cardInformation.rechargeProductId]))
      .pipe(
        map((products: Product[]) => {
          this.saleService.resetOrder();
          let paymentAmount = -cardInformation.balance;
          if(customPaymentAmount) {
            paymentAmount = customPaymentAmount;
            if (outstandingOvertimeInformation?.overtimeFeeProduct) {
              const overtimeFeePaymentAmount = outstandingOvertimeInformation.overtimeFeeProduct.price.value * outstandingOvertimeInformation.overtimeFeeQuantity;
              paymentAmount -= overtimeFeePaymentAmount;
            }
          }

          const product = products[0];
          const orderLine = new OrderLine(
            product,
            1,
            new Money(paymentAmount, product.price.currencyCode),
            cardInformation.name);
          this.saleService.order.orderLines.push(orderLine);

          if (outstandingOvertimeInformation?.overtimeFeeProduct) {
            const orderLine = new OrderLine(
              outstandingOvertimeInformation.overtimeFeeProduct,
              outstandingOvertimeInformation.overtimeFeeQuantity,
              null,
              cardInformation.name);
            this.saleService.order.orderLines.push(orderLine);
          }

          this.saleService.order.type = OrderType.Sale;
          if (managerCardNumber?.length) {
            this.saleService.order.updateProperties('confirmation_manager_card', managerCardNumber);
          }
          return this.saleService.order;
        }),
      );
  }

  createPayoutOrder(cardInformation: CardInformation, refundTotalAmount: number, managerCardNumber: string = ''): Observable<Order> {
    return from(this.vuCommunicationService.vuHttp
      .getProductsByIds([cardInformation.payoutProductId]))
      .pipe(
        map((products: Product[]) => {
          this.saleService.resetOrder();
          const product = products[0];
          const orderLine = new OrderLine(
            product,
            1,
            new Money(-refundTotalAmount, product.price.currencyCode),
            cardInformation.name);
          this.saleService.order.orderLines.push(orderLine);

          this.saleService.order.type = OrderType.Sale;
          if (managerCardNumber?.length) {
            this.saleService.order.updateProperties('confirmation_manager_card', managerCardNumber);
          }
          return this.saleService.order;
        }),
      );
  }


}
