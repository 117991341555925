import { NgModule, ModuleWithProviders } from '@angular/core';
import { LoggingService } from '../../services/logging/logging.service';
import { DispatcherService } from '../../services/dispatcher.service';
import { SaleService } from '../../services/sale.service';
import { ConfigurationService } from '../../services/configuration/configuration.service';
import { AdditionalPropertiesConfigurationService } from '../../services/configuration/additional-properties-configuration.service';
import { VuLoggingService } from '../../services/vu/vu-logging.service';
import { VuCommunicationService } from '../../services/vu/vu-communication.service';
import { MessageService } from '../../services/message.service';
import { UiService } from '../../services/ui.service';
import { ExternalApiService } from '../../services/vu/external-api/external-api.service';
import { ExternalApiSimulatorService } from '../../services/vu/external-api/external-api-simulator.service';
import { CreditCardTerminalSimulatorService } from '../../services/credit-card-terminal-simulator.service';

import { InMemoryDataService } from '../../services/in-memory-data.service';
import { VuHttpService } from '../../services/vu/http/vu-http.service';
import { VuHttpSimulatorService } from '../../services/vu/http/vu-http-simulator.service';
import { TestingService } from '../../services/testing.service';
import { SignalRService } from '../../services/vu/connection/vu-connection-signalr.service';
import { SignalRSimulatorService } from '../../services/vu/connection/vu-connection-simulator.service';
import { AssetsService } from '../../services/assets.service';
import { ThemeService } from '../../services/theme.service';
import { MachinesSupervisorService } from '../../services/machines/machines-supervisor.service';
import { MachinesInactivityService } from '../../services/machines/machines-inactivity.service';
import { LanguageService } from '../../services/language.service';
import { StoreHistoryService } from '../../services/store-history.service';
import { MachinePostSaleService } from '../../services/machines/machine-post-sale.service';
import { MachineTicketService } from '../../services/machines/machine-ticket.service';
import { MachinePayoutService } from '../../services/machines/machine-payout.service';
import { MachineSaleShopService } from '../../services/machines/machine-sale.service';
import { MachineRootService } from '../../services/machines/machine-root.service';
import { MachineNonOperationalService } from '../../services/machines/machine-non-operational.service';
import { NonOperationalService } from '../../services/non-operational.service';
import { ScreenSaverService } from '../../services/screen-saver.service';
import { MachineScreenSaverService } from '../../services/machines/machine-screen-saver.service';
import { MachineLiteSaleService } from '../../services/machines/lite/machine-lite-sale.service';
import { MachineLitePostSaleService } from '../../services/machines/lite/machine-lite-post-sale.service';
import { LiteDisplayService } from '../../services/lite/lite-display.service';
import { LiteDisplayStepsService } from '../../services/lite/lite-display-steps.service';
import { TurnstileSimulatorService } from '../../services/turnstile/turnstile-simulator.service';
import { TouchTileSimulatorService } from '../../services/touch-tile/touch-tile-simulator.service';
import { TouchTileService } from '../../services/touch-tile/touch-tile.service';
import { LiteTouchTileService } from '../../services/lite/lite-touch-tile.service';
import { DemoPaymentService } from '../../services/payment/demo-payment.service';
import { TurnstileService } from '../../services/turnstile/turnstile.service';
import { BarcodeReaderService } from '../../services/barcode/barcode-reader.service';
import { RfidCardReaderService } from '../../services/rfid-card/rfid-card-reader.service';
import { BarcodeReaderSimulatorService } from '../../services/barcode/barckode-reader-simulator.service';
import { RfidCardReaderSimulatorService } from '../../services/rfid-card/rfid-card-reader-simulator.service';
import { TicketService } from '../../services/ticket/ticket.service';
import { TicketSimulatorService } from '../../services/ticket/ticket-simulator.service';
import { MachineTicketActivationService } from '../../services/machines/machine-ticket-activation.service';
import { DisplayConfigurationSimulatorService } from '../../services/display/configuration/display-configuration-simulator.service';
import { DisplayGridWorkflowService } from '../../services/display/grid/display-grid-workflow.service';
import { ProductGridWorkflowService } from '../../services/display/grid/payment/product/product-grid-workflow.service';
import { CategoryGridWorkflowService } from '../../services/display/grid/base/category/category-grid-workflow.service';
import { StaticGridWorkflowService } from '../../services/display/grid/base/static/static-grid-workflow.service';
import { DebugGridWorkflowService } from '../../services/display/grid/base/debug/debug-grid-workflow.service';
import { PrintGridWorkflowService } from '../../services/display/grid/base/print/print-grid-workflow.service';
import { TicketGridWorkflowService } from '../../services/display/grid/ticket/ticket/ticket-grid-workflow.service';
import { RfidCardGridWorkflowService } from '../../services/display/grid/rfid/rfid-card/rfid-card-grid-workflow.service';
import { CIOBoardEventGridWorkflowService } from '../../services/display/grid/cioboard/cioboard-event/cioboard-event-grid-workflow.service';
import { PinGridWorkflowService } from '../../services/display/grid/pin/pin/pin-grid-workflow.service';
import { OpenFmcuGridWorkflowService } from '../../services/display/grid/fmcu/fmcu-action/fmcu-action-grid-workflow.service';
import { LogisticsRequestSimulatorService } from '../../services/logistics-request/logistics-request-simulator.service';
import { MachineGateService } from '../../services/machines/machine-gate.service';
import { ModalService } from '../../services/gui/modal/modal-service';
import { CashPaymentGridWorkflowService } from '../../services/display/grid/payment/cash-payment/cash-payment-grid-workflow.service';
import { StepsListWorkflowService } from '../../services/display/grid/payment/steps-list/steps-list-workflow.service';
import { PaymentConfirmationGridWorkflowService } from '../../services/display/grid/payment/payment-confirmation/payment-confirmation-workflow.service';
import { PaymentCancellationGridWorkflowService } from '../../services/display/grid/payment/payment-cancellation/payment-cancellation-workflow.service';
import { CashRefundGridWorkflowService } from '../../services/display/grid/payment/cash-refund/cash-refund-grid-workflow.service';
import { CashRefundConfirmationGridWorkflowService } from '../../services/display/grid/payment/cash-refund/cash-refund-confirmation/cash-refund-confirmation-workflow.service';
import { DisplayGridInactivityService } from '../../services/display/grid/display-grid-inactivity.service';
import { PdfViewerGridWorkflowService } from '../../services/display/grid/viewers/pdf-viewer/pdf-viewer-grid-workflow.service';
import { NotificationService } from '../../services/notification/notification.service';
import { CIOBoardService } from '../../services/cioboard/cioboard.service';
import { ServerActionGridWorkflowService } from '../../services/display/grid/base/server-action/server-action-grid-workflow.service';
import { BasketGridWorkflowService } from '../../services/display/grid/payment/basket/basket-grid-workflow.service';
import { CardPaymentGridWorkflowService } from '../../services/display/grid/payment/card-payment/card-payment-grid-workflow.service';
import { OutputInputService } from '../../services/output-input/output-input.service';
import { InputGridWorkflowService } from '../../services/display/grid/text-input/input/input-grid-workflow.service';
import { KeypadGridWorkflowService } from '../../services/display/grid/text-input/keypad/keypad-grid-workflow.service';
import { CreateOrderGridWorkflowService } from '../../services/display/grid/payment/create-order/create-order-grid-workflow.service';
import { ImageViewerGridWorkflowService } from '../../services/display/grid/viewers/image-viewer/image-viewer-grid-workflow.service';
import { MarkdownViewerGridWorkflowService } from '../../services/display/grid/viewers/markdown-viewer/markdown-viewer-grid-workflow.service';
import { DisplayItemAssetsService } from '../../services/display/display-item-assets.service';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { CardPaymentAmountInputGridWorkflowService } from '../../services/display/grid/payment/card-payment/card-payment-amount-input-grid-workflow';
import { ShopRedirectService } from '../../services/shop-redirect.service';
import { CardDispenserService } from '../../services/card-dispenser/card-dispenser.service';
import { CardDispenserGridWorkflowService } from '../../services/display/grid/rfid/card-dispenser/card-dispenser-grid-workflow.service';
import { CardDispenserRefundGridWorkflowService } from '../../services/display/grid/rfid/card-dispenser/card-dispenser-refund-grid-workflow.service';
import { CardDispenserCaptureGridWorkflowService } from '../../services/display/grid/rfid/card-dispenser/card-dispenser-capture-grid-workflow.service';
import { CardDispenserReleaseCardGridWorkflowService } from '../../services/display/grid/rfid/card-dispenser/card-dispenser-release-grid-workflow.service';
import { MoneyExchangeGridWorkflowService } from '../../services/display/grid/payment/money_exchange/money-exchange-grid-workflow.service';
import { CardDispenserMonitorGridWorkflowService } from '../../services/display/grid/rfid/card-dispenser/card-dispenser-monitor-grid-workflow.service';
import { MachineCardRechargeService } from '../../services/machines/machine-card-recharge.service';
import { UnrestrictedCashPaymentService } from '../../services/payment/unrestricted-cash-payment.service';
import { ExternalUseService } from '../external-base/services/external-use.service';
import { LocaleDatePipe } from 'src/app/pipes/locale-date';
import { RechargeCardService } from '../recharge-card/services/recharge-card.service';
import { MachineSaleCashlessService } from 'src/app/services/machines/machine-sale-cashless.service';
import { CashlessCardProviderService } from 'src/app/services/payment/cashless-providers/cashless-card-provider.service';
import { CashlessBluecodeProviderService } from 'src/app/services/payment/cashless-providers/cashless-bluecode-provider.service';
import { CashlessGiftCardProviderService } from 'src/app/services/payment/cashless-providers/cashless-giftcard-provider.service';
import { PaymentsMethodService } from '../../services/payment/payment-method.service';
import { HourMinuteSecondsPipe } from 'src/app/pipes/hour-minute-second';
import { MessageModalService } from '../message-modal/services/message-modal.service';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { TouchService } from 'src/app/services/gui/touch/touch.service';
import { ProductValidatorService } from 'src/app/services/product/product-validator.service';
import { CashRefundService } from '../recharge-card/services/cash-refund/cash-refund.service';
import { CardDispenserRestoreCardService } from '../card-dispenser-restore-card/services/card-dispenser-restore-card.service';
import { GiftCardDispenserRefundGridWorkflowService } from 'src/app/services/display/grid/rfid/card-dispenser/gift-card-dispenser-refund-grid-workflow.service';
import { PrintService } from 'src/app/services/print/print.service';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    LocaleDatePipe,
    HourMinuteSecondsPipe,
  ],
  providers: [

  ],
  imports: [
    CommonModule,
    LoadingSpinnerModule,
  ],
  exports: [
    SafeHtmlPipe,
    LocaleDatePipe,
    HourMinuteSecondsPipe,
  ]

})
export class SharedModule {
  static forRoot(): ModuleWithProviders<unknown> {
    return {
      ngModule: SharedModule,
      providers: [
        InMemoryDataService,
        VuHttpService,
        VuHttpSimulatorService,
        ConfigurationService,
        MessageService,
        LoggingService,
        TestingService,
        DispatcherService,
        SignalRService,
        SignalRSimulatorService,
        VuLoggingService,
        UiService,
        AssetsService,
        ThemeService,
        MachinesSupervisorService,
        MachinesInactivityService,
        LanguageService,
        StoreHistoryService,
        ProductValidatorService,
        SaleService,
        MachinePostSaleService,
        MachineTicketService,
        MachinePayoutService,
        MachineSaleShopService,
        MachineSaleCashlessService,
        MachineRootService,
        MachineNonOperationalService,
        NonOperationalService,
        CashlessCardProviderService,
        CashlessBluecodeProviderService,
        CashlessGiftCardProviderService,
        VuCommunicationService,
        ScreenSaverService,
        MachineScreenSaverService,
        MachineLiteSaleService,
        MachineLitePostSaleService,
        LiteDisplayService,
        LiteDisplayStepsService,
        AdditionalPropertiesConfigurationService,
        TurnstileSimulatorService,
        TouchTileSimulatorService,
        TouchTileService,
        LiteTouchTileService,
        DemoPaymentService,
        TurnstileService,
        BarcodeReaderService,
        RfidCardReaderService,
        CardDispenserService,
        BarcodeReaderSimulatorService,
        RfidCardReaderSimulatorService,
        TicketService,
        TicketSimulatorService,
        CreditCardTerminalSimulatorService,
        MachineTicketActivationService,
        DisplayConfigurationSimulatorService,
        DisplayGridWorkflowService,
        ProductGridWorkflowService,
        CategoryGridWorkflowService,
        StaticGridWorkflowService,
        DebugGridWorkflowService,
        PrintGridWorkflowService,
        TicketGridWorkflowService,
        RfidCardGridWorkflowService,
        CardDispenserGridWorkflowService,
        CardDispenserRefundGridWorkflowService,
        CardDispenserReleaseCardGridWorkflowService,
        CardDispenserCaptureGridWorkflowService,
        CardDispenserMonitorGridWorkflowService,
        CIOBoardEventGridWorkflowService,
        PinGridWorkflowService,
        OpenFmcuGridWorkflowService,
        LogisticsRequestSimulatorService,
        MachineGateService,
        ModalService,
        MessageModalService,
        CashPaymentGridWorkflowService,
        StepsListWorkflowService,
        PaymentConfirmationGridWorkflowService,
        PaymentCancellationGridWorkflowService,
        CashRefundGridWorkflowService,
        CashRefundConfirmationGridWorkflowService,
        DisplayGridInactivityService,
        PdfViewerGridWorkflowService,
        NotificationService,
        CIOBoardService,
        ServerActionGridWorkflowService,
        BasketGridWorkflowService,
        CardPaymentGridWorkflowService,
        OutputInputService,
        InputGridWorkflowService,
        KeypadGridWorkflowService,
        CreateOrderGridWorkflowService,
        ImageViewerGridWorkflowService,
        MarkdownViewerGridWorkflowService,
        DisplayItemAssetsService,
        ExternalApiService,
        ExternalApiSimulatorService,
        CardPaymentAmountInputGridWorkflowService,
        ShopRedirectService,
        MoneyExchangeGridWorkflowService,
        MachineCardRechargeService,
        UnrestrictedCashPaymentService,
        ExternalUseService,
        RechargeCardService,
        PaymentsMethodService,
        CashRefundService,
        CardDispenserRestoreCardService,
        GiftCardDispenserRefundGridWorkflowService,
        {
          provide: HAMMER_GESTURE_CONFIG,
          useClass: HammerGestureConfig
        },
        TouchService,
        PrintService,
      ]
    };
  }
}
