import { GlobalSettings } from 'src/app/lib/global-settings';
import { Money } from 'src/app/lib/money/money';
import { BaseInfo } from './base-info';
import { Product } from 'src/app/lib/product';

export class OrderLineInfo extends BaseInfo {
  price: Money;
  productId: number;
  productName: string;
  quantity: number;
  product: Product;

  static createFromAny(object: any): OrderLineInfo | null {
    if (object === null || object === undefined) {
      return null;
    }
    const orderLineInfo = new OrderLineInfo();
    orderLineInfo.price = object.price != null ? new Money(object.price, GlobalSettings.getCurrencyCode()) : Money.empty;
    orderLineInfo.productId = object.product_id;
    orderLineInfo.productName = object.product_name || '';
    orderLineInfo.quantity = object.quantity;
    return orderLineInfo;
  }

  get totalAmount(): Money {
    return new Money(this.price.value * this.quantity, this.price.currencyCode);
  }

  getTranslateName(language: string): string {
    return this.product?.translateName(language) || this.product?.name || this.productName;
  }
}
